// @flow
import React, { useEffect } from 'react';
import type { Node } from 'react';
import { isAfter } from 'date-fns';
import { initPopElements, removePopElements } from '../../../helpers';

import type { UIVacationStat } from '../../../types';

type Props = {
  vacationsStats: UIVacationStat,
  onAddVacationsDays: Function,
  calendarOpened: boolean,
  selectedYear: number,
};

const ProfileVacations = (props: Props): Node => {
  useEffect(() => {
    initPopElements();

    return () => removePopElements();
  }, []);

  const {
    vacationsStats, onAddVacationsDays, calendarOpened, selectedYear,
  } = props;
  const buttonText = calendarOpened ? 'Close calendar' : 'Manage vacations';
  const isAfterJune30th = isAfter(new Date(), new Date(selectedYear, 5, 30));

  return (
    <div className="card">
      <div className="card-body pt-1">
        <ul className="list-group list-group-flush">
          <li className="list-group-item px-1">
            {`Vacation days for ${selectedYear}`}
          </li>
          <li className="list-group-item px-1">
            <span className="text-muted">Alloted days: </span>
            {vacationsStats.alloted}
          </li>
          <li className="list-group-item px-1">
            <span className="text-muted">Carried over days: </span>
            <span data-toggle="tooltip" title="Unusable after July 1st">
              {vacationsStats.carriedOver} <span className="text-danger">*</span>
            </span>
          </li>
          <li className="list-group-item px-1">
            <span className="text-muted">Total days: </span>
            {vacationsStats.totalAlloted}
          </li>
          <li className="list-group-item px-1">
            <span className="text-muted">Remaining (Alloted) days: </span>
            {vacationsStats.remaining}
          </li>
          {!isAfterJune30th && (
            <li className="list-group-item px-1">
              <span className="text-muted">Remaining (Carried over) days: </span>
              <span data-toggle="tooltip" title="Unusable after July 1st">
                {vacationsStats.remainingCarriedOverUntilJuly} <span className="text-danger">*</span>
              </span>
            </li>
          )}
          <li className="list-group-item px-1">
            <span className="text-muted">Total remaining days: </span>
            {vacationsStats.remaining + (isAfterJune30th ? 0 : vacationsStats.remainingCarriedOverUntilJuly)}
          </li>
          <button
            type="button"
            className="btn btn-primary primary-color"
            onClick={onAddVacationsDays}
          >
            {buttonText}
          </button>
        </ul>
      </div>
    </div>
  );
};

export default ProfileVacations;
